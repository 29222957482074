import React from 'react';
import "./InsurnaceDash.css"

function InsurnaceDash() {
  return (
    <div className='isurnace-coantiner'>
        <div className='isnurance-header'>
        isnurance-header
        </div>
        <div className='insurnace-mainarea'>
        insurnace-mainarea
        </div>
        <div className='insurnace-footer'>
        insurnace-footer
        </div>
      
    </div>
  )
}

export default InsurnaceDash
