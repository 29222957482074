import React from 'react'
import './Schedules.css'
import Footer from '../../components/Footer';
import Profile_Header from '../../components/Profile_Header';
function Schedules() {
  return (
    <div>
       <Profile_Header/>
      Schedules
      <Footer/>
    </div>
  )
}

export default Schedules;
