import React from 'react'
import "./PatientDash.css"
function PatientDash() {
  return (
    <div className='patient-coantiner'>
    <div className='patient-header'>
    patient-header
    </div>
    <div className='patient-mainarea'>
    patient-mainarea
    </div>
    <div className='patinet-footer'>
    patinet-footer
    </div>
  
</div>
  )
}

export default PatientDash
