import React from 'react'
import "./ProviderDash.css"
function ProviderDash() {
  return (
    <div className='provider-container'>
    <div className='provider-header'>
    provider-heade
    </div>
    <div className='provider-mainarea'>
    provider-mainarea
    </div>
    <div className='provider-footer'>
    provider-footer
    </div>
  
</div>
  )
}

export default ProviderDash
