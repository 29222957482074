import React from 'react'
import "./TestCenterDash.css"
function TestCenterDash() {
  return (
    <div className='testingcenter-container'>
    <div className='testingcenter-header'>
    testingcenter-header
    </div>
    <div className='testingcenter-mainarea'>
    testingcenter-mainarea
    </div>
    <div className='testingcenter-footer'>
    testingcenter-footer
    </div>
  
</div>
  )
}

export default TestCenterDash;
