import React from 'react'
import './PharmaDash.css'
function PharmaDash() {
  return (
    <div className='pharma-container'>
    <div className='pharma-header'>
    pharma-header
    </div>
    <div className='pharma-mainarea'>
    pharma-mainarea
    </div>
    <div className='pharma-footer'>
    pharma-footer
    </div>
  
</div>
  )
}

export default PharmaDash
