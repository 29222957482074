import React from 'react'
import Footer from '../../components/Footer';
import Profile_Header from '../../components/Profile_Header';
function Profile() {
  return (
    <div>
       <Profile_Header/>
      profile
      <Footer/>
    </div>
  )
}

export default Profile
